<template>
  <div class="main">
      <div class="profile">
            <p class="Pro mt-15 ml-12"><strong>Profile</strong> </p>
        <div class="row">
         <div @click="navigate('home')" :class="$route.name  != 'home'  ? 'iconList' : 'pointer'">
             <v-icon
                large
                :class="$route.name  != 'home'  ? 'iconColorW' : 'iconColor'"
                >
                mdi-dialpad
                </v-icon>
                <p  :class="$route.name  != 'home'  ? 'iconColorW' : 'iconColor'">Home</p>
         </div> 
          <div @click="navigate('OrderList')" :class="$route.name  != 'OrderList'  ? 'iconList' : 'pointer'">
             <v-icon
                large
                :class="$route.name  != 'OrderList'  ? 'iconColorW' : 'iconColor'"
                >
                mdi-book-edit
                </v-icon>
                <p  :class="$route.name  != 'OrderList'  ? 'iconColorW' : 'iconColor'">My Orders</p>
         </div> 
          </div>
        <div class="row">
          <div @click="navigate('notification')" :class="$route.name  != 'notification'  ? 'iconList' : 'pointer'">
             <v-icon
                large
                :class="$route.name  != 'notification'  ? 'iconColorW' : 'iconColor'"
                >
                mdi-bell
                </v-icon>
                <p :class="$route.name  != 'notification'  ? 'iconColorW' : 'iconColor'">Notifications</p>
         </div> 
            <div @click="navigate('profileDashborad')" :class="$route.name  != 'profileDashborad'  ? 'iconList' : 'pointer'">
             <v-icon
                large
                :class="$route.name  != 'profileDashborad'  ? 'iconColorW' : 'iconColor'"
                >
                mdi-account-circle-outline
                </v-icon>
                <p :class="$route.name  != 'profileDashborad'  ? 'iconColorW' : 'iconColor'">Account Details</p>
         </div> 
        </div>
          <div class="row">
          <div @click="signout()" :class="$route.name  != 'notification'  ? 'iconList' : 'pointer'">
             <v-icon
                large
                :class="$route.name  != 'notification'  ? 'iconColorW' : 'iconColor'"
                >
                mdi-lock
                </v-icon>
                <p :class="$route.name  != 'notification'  ? 'iconColorW' : 'iconColor'">Logout</p>
         </div>  
         <div class="pointer"></div>
        </div>
      </div>
      <div class="details">
             <div class="ml-2 display-1 font-weight-thin underline">
              Wallet Balance: {{DisplayCurrency(User.walletBalance)}}
             </div>
           <router-view></router-view>
      </div>
  </div>
</template>

<script>
import currencyFormatter from 'currency-formatter'
export default {
    created(){
        this.navigate("OrderList")
         if(Date.now() > parseInt(this.LoginTime)){
        this.$store.commit("setSnackbar", true)
          this.$store.commit("setServerMessage", "Please Login To Continue")
          let payload ={
              route: this.$router,
        }
        this.$store.commit("setSignOut", payload)
        return
      }
    },
    metaInfo: {
    title: `Profile`,
    meta: [
      {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      {name: 'description', content: 'Ecommerce'}
    ]
  },
  computed:{
    User() {
      return this.$store.getters.User;
    },
    LoginTime() {
      return this.$store.getters.LoginTime;
   },
  },
    methods:{
      signout(){
        let payload ={
            route: this.$router,
        }
        this.$store.commit("setSignOut", payload)
      },
         DisplayCurrency(payload) {
        return currencyFormatter.format(payload, {
            code: "NGN"
        });
    },
        navigate(payload){
             if(payload == 'notification'){
                this.$store.commit("setSnackbar", true);
              this.$store.commit("setServerMessage", "No Notification")
               return
             }
             this.$router.push({name:payload});
        }
    },
       data: () => ({
             items: [
                 {icon: 'mdi-dialpad', title: 'Dashboard'},
                 {icon: 'mdi-dialpad', title: 'View Orders'},
                 {icon: 'mdi-dialpad', title: 'Notifications'},
                 {icon: 'mdi-dialpad', title: 'Account Details'},
             ]
       })

}
</script>

<style scoped>
.main{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
   grid-auto-rows: minmax(100vh, auto);
  margin: 0 auto;
  padding: 0px;
  grid-template-areas:
    "profile details details"
}
.profile{
 grid-area: profile;
 background-color: #f8fcff;
}
.details{
 grid-area: details;
 background-color: #fff;
}
.row{
    display: flex;
    justify-content: space-evenly;
    margin: 20px;
}
.iconColor{
  color: #1c63ba;
}
.iconColorW{
  color: white;
}
.pointer{
width: 150px;
height: 170px;
display: flex;
flex-direction: column;
border-radius: 10px;
margin: 5px;
margin-bottom: 5px !important;
justify-content: center;
align-items: center;
  background-color: transparent;
}
.iconList{
cursor: pointer;
width: 150px;
height: 170px;
display: flex;
flex-direction: column;
border-radius: 10px;
margin: 5px;
margin-bottom: 5px !important;
justify-content: center;
align-items: center;
background-color: #1c63ba;
}
.color{
  color: #fff;
  margin-top: 5px;
}
.Pro{
 width: 120px;
  height: 24px;
  font-family: Poppins;
  font-size: 36px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.67;
  letter-spacing: normal;
  text-align: center;
  color: #1c63ba;
}
@media only screen and (max-width: 700px) {
  .main{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
   grid-auto-rows: minmax(100vh, auto);
  margin: 0 auto;
  padding: 0px;
  grid-template-areas:
    "details"
}
.profile{
 grid-area: profile;
 background-color: #f8fcff;
 display: none;
}
}
</style>